import styled from 'styled-components'
import { main, mobileMediaQuery } from '@caterdesk/ui--theme'
import { drawerWidth } from '../basket-drawer/index.styles'

export const Nav = styled.nav<{ basketIsOpen?: boolean }>`
  padding: 16px 32px;
  position: sticky;
  top: 0;
  z-index: 3;
  background: ${main.colors.canvas};
  width: ${(p) => (p.basketIsOpen ? `calc(100% - ${drawerWidth})` : '100%')};
  transition: width 0.3s ease-in-out;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);

  @media ${mobileMediaQuery} {
    position: initial;
    width: 100%;
    padding: 12px 16px;
  }
`

export const NavList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
`

export const NavListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${main.colors.text};
  font-size: ${main.fontSizes.s};
  font-weight: ${main.fontWeights.medium};
  & > div {
    position: relative;
    top: 8px;
  }

  @media ${mobileMediaQuery} {
    margin: 0;
    font-size: ${main.fontSizes.xs};
  }
`

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media ${mobileMediaQuery} {
    gap: 6px;
  }
`
