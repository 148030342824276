import React from 'react'
import { useMedia } from 'react-use'
import { match } from 'ts-pattern'
import { resolveLogoVariant } from '@caterdesk/ui--feedr-logo'
import Dropdown, { DropdownLink } from '@caterdesk/ui--dropdown'
import { main, mobileMediaQuery } from '@caterdesk/ui--theme'
import { amountToPriceString, Currency } from '@caterdesk/utils--money'
import { useAuthState } from '../states/auth'
import { Flex, Nav, NavList, NavListItem } from './index.styles'
import Mobile from './mobile'
import { loggedInLinks, loggedInManagerLinks } from './constants'
import RequestAQuoteButton from './requestAQuoteButton'
import { useBasketState } from '../states/basket'
import { UserRole } from '@/generated/graphql'
import { Button, Chip, Container, Tooltip } from '@mui/material'
import { ShoppingCart } from '@mui/icons-material'
import Link, { useLocaleLink, useRouter } from '@/components/Link'
import useTenant from '@/hooks/useTenant'

type Props = {
  existingOrderData?: { itemQuantity: number; subtotalExTax: string; currency: string } | null
  basketIsOpen?: boolean
  newItemWasJustAddedToBasket?: boolean
  onClickBasketPreview?: () => void
}

const Navigation: React.FC<Props> = ({
  existingOrderData,
  basketIsOpen: basketIsOpenProp,
  newItemWasJustAddedToBasket,
  onClickBasketPreview,
}) => {
  const router = useRouter()
  const tenant = useTenant()
  const generateLocaleLink = useLocaleLink()
  const authState = useAuthState()
  const basketState = useBasketState()
  const isMobileScreen = useMedia(mobileMediaQuery, false)

  const { basket } = basketState

  const basketIsOpen = typeof basketIsOpenProp === 'boolean' ? basketIsOpenProp : basketState.isOpen
  const showBasketPreview = basket || existingOrderData
  const newItemWasJustAdded = existingOrderData
    ? newItemWasJustAddedToBasket
    : basketState.newItemWasJustAdded
  const itemCount = existingOrderData ? existingOrderData.itemQuantity : (basket?.itemCount ?? 0)
  const currency = existingOrderData ? existingOrderData.currency : (basket?.currency ?? '')
  const subtotal = existingOrderData ? existingOrderData.subtotalExTax : (basket?.subtotal ?? '')

  const handleBasketIconClicked = () => {
    if (onClickBasketPreview) {
      onClickBasketPreview()
    } else if (basket) {
      basket.itemCount > 0 && basketState.toggleIsOpen(true)
    }
  }

  return (
    <Nav basketIsOpen={basketIsOpen}>
      <Container fixed>
        <NavList>
          <Flex>
            <NavListItem>
              <Link href="/">
                <img
                  src={resolveLogoVariant({
                    setToRainbowDuringPride: true,
                    logos: tenant.marketing.logos,
                  })}
                  alt="Logo"
                  height={isMobileScreen ? 35 : 50}
                />
              </Link>
            </NavListItem>
            <NavListItem>CATERING</NavListItem>
          </Flex>
          <Flex>
            <RequestAQuoteButton />
            {showBasketPreview && (
              <NavListItem>
                <Tooltip
                  title={
                    <>
                      <div>Added to basket!</div>
                      <div>
                        Subtotal: {amountToPriceString(currency as Currency, subtotal, true)}
                      </div>
                    </>
                  }
                  placement="bottom"
                  open={(isMobileScreen && newItemWasJustAdded) || false}
                >
                  <Chip
                    clickable
                    onClick={handleBasketIconClicked}
                    color={itemCount > 0 ? 'primary' : 'secondary'}
                    size="medium"
                    label={
                      isMobileScreen
                        ? itemCount
                        : `${itemCount} item${
                            itemCount === 1 ? '' : 's'
                          }, ${amountToPriceString(currency as Currency, subtotal, !existingOrderData)}`
                    }
                    icon={<ShoppingCart fontSize="medium" />}
                  />
                </Tooltip>
              </NavListItem>
            )}
            {isMobileScreen ? (
              <Mobile />
            ) : (
              match(authState)
                .with({ type: 'authenticated' }, ({ user }) => (
                  <NavListItem>
                    <Dropdown title={`${user.firstName} ${user.lastName}`}>
                      {(user.role === UserRole.Manager ? loggedInManagerLinks : loggedInLinks).map(
                        (link) => (
                          <DropdownLink
                            key={link.name}
                            name={link.name}
                            color={link.color || main.colors.text}
                            href={generateLocaleLink(link.url)}
                          />
                        ),
                      )}
                    </Dropdown>
                  </NavListItem>
                ))
                .with({ type: 'loading' }, { type: 'unauthenticated' }, () => (
                  <NavListItem>
                    <Link href={`/authentication/login?destination=${router.asPath}`} passHref>
                      <Button variant="contained" size="large">
                        Sign in
                      </Button>
                    </Link>
                  </NavListItem>
                ))
                .exhaustive()
            )}
          </Flex>
        </NavList>
      </Container>
    </Nav>
  )
}

export default Navigation
