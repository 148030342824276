import React, { FC } from 'react'
import styled, { useTheme } from 'styled-components'
import {
  TextExtraExtraSmall,
  TextExtraLarge,
  TextExtraSmall,
  TextMedium,
} from '@caterdesk/ui--typography'
import { mobileMediaQuery } from '@caterdesk/ui--theme'
import Stack from '@caterdesk/ui--stack'
import BrokenBowl from './broken-bowl'
import { CustomError } from '../../utils/errors'
import { Button } from '@mui/material'
import Link from '@/components/Link'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;

  @media ${mobileMediaQuery} {
    position: relative;
    top: 0;
    transform: none;
    margin: 40px 0;
    padding: 0 40px;
  }
`

export type Props = {
  error: CustomError
  errorMessage: string
  showSearchVendorsCta?: boolean
  showEditBasketCta?: boolean
  showViewBasketCta?: boolean
  onChatToUsClick: () => void
}

const ErrorPage: FC<Props> = ({
  error,
  errorMessage,
  showSearchVendorsCta,
  showEditBasketCta,
  showViewBasketCta,
  onChatToUsClick,
}) => {
  const theme = useTheme()

  return (
    <Container>
      <BrokenBowl />
      <Stack spacing={16} direction="vertical">
        <TextExtraLarge
          style={{ color: theme.colors.text_primary, fontWeight: theme.fontWeights.semi }}
        >
          This is awkward!
        </TextExtraLarge>

        <Stack spacing={8} direction="vertical">
          <TextMedium>
            {showViewBasketCta
              ? 'Some changes have been made to your basket'
              : "Something's gone wrong"}
          </TextMedium>
          <TextExtraExtraSmall
            style={{ color: theme.colors.text_semi_muted, fontWeight: theme.fontWeights.medium }}
          >
            {errorMessage}
          </TextExtraExtraSmall>
        </Stack>

        <Stack spacing={32} direction="vertical">
          {showViewBasketCta && error.permalink && error.basketId ? (
            <Link href={`/office-catering/vendors/${error.permalink}/?basketId=${error.basketId}`}>
              <Button variant="contained">View basket</Button>
            </Link>
          ) : (
            <Stack spacing={8}>
              <Button
                variant={showSearchVendorsCta || showEditBasketCta ? 'outlined' : 'contained'}
                onClick={() => window.location.reload()}
              >
                Try again
              </Button>

              {showSearchVendorsCta && (
                <Link href="/office-catering/vendors">
                  <Button variant="contained">Search vendors</Button>
                </Link>
              )}

              {showEditBasketCta && error.permalink && error.basketId && (
                <Link
                  href={`/office-catering/vendors/${error.permalink}/?basketId=${error.basketId}`}
                >
                  <Button variant="contained">Back to vendor</Button>
                </Link>
              )}
            </Stack>
          )}
          <Stack spacing={4}>
            <TextExtraSmall>Need help?</TextExtraSmall>
            <Button size="small" onClick={onChatToUsClick}>
              Chat to us
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  )
}

export default ErrorPage
