import styled from 'styled-components'
import Box from '@caterdesk/ui--box'
import { mobileMediaQuery } from '@caterdesk/ui--theme'

export const drawerWidth = '407px'

export const Drawer = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: ${drawerWidth};
  height: 100%;
  background-color: ${(p) => p.theme.colors.background_dark};
  top: 0;
  right: 0;
  z-index: 1;
  overflow: auto;
  transform: ${(p) => (p.isOpen ? 'translateX(0%)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out;

  @media ${mobileMediaQuery} {
    width: 100%;
  }
`

export const Header = styled(Box)`
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  left: 16px;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
`

export const ItemCard = styled(Box)<{ error?: boolean }>`
  border-radius: 5px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.03);
  width: 100%;
  border: ${(p) => (p.error ? `1px solid ${p.theme.colors.error_main}` : 0)};
`

export const InputBar = styled.div`
  display: flex;
  gap: 8px;
  cursor: pointer;

  > *:not(:last-child) {
    width: -webkit-fill-available;
  }
`

export const DrawerContent = styled(Box)`
  height: auto;
  flex-grow: 1;
`

export const Content = styled.div`
  display: flex;
  min-height: 100%;
  gap: 16px;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;

  > :first-child {
    flex-grow: 1;
  }
`

export const Footer = styled(Box)`
  position: sticky;
  bottom: 0;
  width: 407px;
  box-shadow: 0px 0px 24px 0px #00000025;

  @media ${mobileMediaQuery} {
    width: 100%;
  }
`

export const ModalWrapper = styled.div`
  > div > div {
    height: auto;
    width: 503px;
    min-height: 0;
    min-width: 0;

    @media ${mobileMediaQuery} {
      height: 100%;
      width: 100%;
    }
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;

  @media ${mobileMediaQuery} {
    flex-direction: column-reverse;
  }
`

export const IconButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
`
