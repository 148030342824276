import { calculateOptions, timePopoverFormat } from '@caterdesk/utils--gm-validation'
import * as dateFns from 'date-fns'
import { UserBasketSettingsLocationFragment } from '@/generated/graphql'

export type UserBasketSettings = {
  initalised?: boolean
  location?: UserBasketSettingsLocationFragment
  date?: number
  time?: string
  headCount?: number
  vendor?: {
    imageUrl?: string
    url?: string
  }
}

export type ValidUserBasketSettings = Required<UserBasketSettings>

export const validateUserBasketSettings = ({
  location,
  date,
  time,
  headCount,
  vendor,
  initalised,
}: UserBasketSettings):
  | {
      type: 'success'
      value: ValidUserBasketSettings
    }
  | { type: 'error' } =>
  location && date && time && headCount
    ? {
        type: 'success',
        value: {
          location,
          date,
          time,
          headCount,
          vendor: vendor ?? {},
          initalised: initalised ?? false,
        },
      }
    : { type: 'error' }

export const computeDeliveryTimeSlots = ({
  deliveryDate,
  minAllowedDateTime,
  useDeliveryTimes,
}: {
  deliveryDate: Date | null
  minAllowedDateTime: Date | null
  useDeliveryTimes?: boolean
}) => {
  if (!deliveryDate) return [] // must select date first to see time options

  const isDeliveryDateBeforeMinDate = // this compares the two dates without the time parts
    minAllowedDateTime && dateFns.isBefore(dateFns.endOfDay(deliveryDate), minAllowedDateTime)
  if (isDeliveryDateBeforeMinDate) return []

  const minTimeOnDay =
    minAllowedDateTime && dateFns.isSameDay(deliveryDate, minAllowedDateTime)
      ? // only return time slot remaining in day
        Number(dateFns.format(minAllowedDateTime, 'HHmm'))
      : // date is in the future, so allow all time slots
        undefined

  return timePopoverFormat({
    ...calculateOptions([], deliveryDate.getTime(), useDeliveryTimes),
    chosenTime: minTimeOnDay,
  })
}
