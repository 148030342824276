import { compact } from 'lodash'
import { UserBasketSettingsLocationFragment, Address } from '@/generated/graphql'
import useTenant from '@/hooks/useTenant'
import { getCountryFromLocale } from '@/helpers/multiRegion'
import { useRouter } from '@/components/Link'
import { basketLocationToAddress } from '@caterdesk/utils--gm-validation'
import { SPLITS, useFeatureFlag } from '@/helpers/useFeatureFlag'

export const useAddressLabel = (address?: UserBasketSettingsLocationFragment | Address) => {
  const router = useRouter()
  const tenant = useTenant()
  const enabledFullAdressLabel = useFeatureFlag(SPLITS.GM_SHOW_FULL_ADDRESS)
  const country = getCountryFromLocale(router.locale || tenant.defaultLocale)
  const basketLocationObject = address ? basketLocationToAddress(address) : undefined

  let addressLabel = basketLocationObject
    ? basketLocationObject.postcode || basketLocationObject.street
    : undefined
  const showFullAddress =
    enabledFullAdressLabel &&
    basketLocationObject &&
    tenant.features?.gmShowFullAddress?.visibility?.regions.includes(country.toUpperCase())
  if (showFullAddress) {
    addressLabel = compact([
      basketLocationObject.flatOrBuilding,
      basketLocationObject.floorOrBuildingName,
      basketLocationObject.street,
      basketLocationObject.city,
      basketLocationObject.postcode,
    ]).join(', ')
  }
  return { addressLabel, showFullAddress }
}
